import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: [ './features.component.css' ]
})
export class FeaturesComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
      this._titleService.setTitle("API Automatic Testing, Mocks, Designer &amp; Other Features - RestCase");
      this._metaService.addTags([
        {name: 'description', content: 'API-first design, API testing, API virtualization services and other features of RestCase.'}
      ], true);
  }

  ngOnInit() {
  }

}
