import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isSticky: boolean = false;
  isCollapsed: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 90 && !(window.pageYOffset > 40 && window.pageYOffset < 90);
  }
  
  constructor() {}

  ngOnInit() {
    this.logoDownload();
  }

  logoDownload() {
    let logo = document.querySelector("#logo");
    let button = document.querySelector("#close-modal-button");
    let overlay = document.querySelector("#overlay");
    let modal = document.querySelector("#modal");

    logo.addEventListener('contextmenu', function (event) {
      event.preventDefault();
      overlay.classList.add("show");
      modal.classList.add("show");
    }, false);

    button.addEventListener('click', function (event) {
      event.preventDefault();
      overlay.classList.remove("show");
      modal.classList.remove("show");
    }, false);
  }

}
