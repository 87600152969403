import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent }        from './home/home.component';
import { FeaturesComponent }    from './features/features.component';
import { ContactComponent }     from './contact/contact.component';
import { DocsComponent }        from './platform/docs/docs.component';
import { DesignComponent }      from './platform/design/design.component';
import { PricingComponent }     from './pricing/pricing.component';
import { CasesComponent }       from './cases/cases.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TestingComponent } from './platform/tests/tests.component';
import { MocksComponent } from './platform/mocks/mocks.component';
import { AboutComponent } from './about/about.component';
import { StartComponent } from './start/start.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { ErrorComponent } from './error/error.component';
import { InsightsComponent } from './platform/insights/insights.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'cases', component: CasesComponent },
  { path: 'get-started', component: StartComponent },
  { path: 'services', component: OurServicesComponent },
  { path: 'platform/docs', component: DocsComponent },
  { path: 'platform/design', component: DesignComponent },
  { path: 'platform/tests', component: TestingComponent },
  { path: 'platform/mocks', component: MocksComponent },
  { path: 'platform/security', component: InsightsComponent },
  { path: '404', component: NotFoundComponent},
  { path: 'error', component: ErrorComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top"
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
