import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: [ './start.component.css' ]
})
export class StartComponent implements OnInit {

  @ViewChild("recaptcha") recaptchaElement: ElementRef;
  
  valForm: FormGroup;
  set userVerified(verified) {
    this._verified = verified;
  }

  get userVerified() {
    return this._verified;
  }

  private _verified: boolean = false;

  constructor(private fb: FormBuilder, private _http: HttpService) {
    const password = new FormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.pattern(
          "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!$%@#£€$*?&]{8,}$"
        )
      ])
    );

    this.valForm = fb.group({
      workspace: [null, Validators.required],
      email: [
        null,
        Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])
      ],
      name: [null, Validators.required],
      accountagreed: [null, Validators.required],
      password: password
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
  }

  ngOnInit() {
    this.addRecaptchaScript();
  }

  renderReCaptch() {
    window["grecaptcha"].render(this.recaptchaElement.nativeElement, {
      sitekey: "6Ldi98QUAAAAAPnB6BYHHWdyTOxZNElSGLQxMC8_",
      callback: response => {
        this.validateCaptchaResponse(response)
          .subscribe(validationResponse => {
            let validationSuccessful = validationResponse[1];
            let validationTimestamp = validationResponse[2];
            let validationErrors = validationResponse[3];
            if (!validationSuccessful) {
              window["grecaptcha"].reset();
            } else {
              this.userVerified = true;
            }
          });
      }
    });
  }

  validateCaptchaResponse(response): Observable<any> {
    return this._http
      .post(`https://api.restcase.com/api/userverify`, ["r", response])
      .pipe(map(r => r.body));
  }

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptch();
    };

    let jsElement;
    let fatherJsElement = document.getElementsByTagName("script")[0];
    if (document.getElementById("recaptcha-jssdk")) {
      this.renderReCaptch();
      return;
    }
    jsElement = document.createElement("script");
    jsElement.id = "recaptcha-jssdk";
    jsElement.src =
      "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
    fatherJsElement.parentNode.insertBefore(jsElement, fatherJsElement);
  }

}
