import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: [ './insights.component.css' ]
})
export class InsightsComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Security API Insights - Features - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'Analyze your API specifications with security and quality insights in order to avoid security vulnerabilities from the design phase.'}
    ], true);
  }

  ngOnInit() {
  }

}
