import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: [ './docs.component.css' ]
})
export class DocsComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("API Interactive Documentation - Features - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'API interactive documentation that is created automatically, supports for enhanced markdown and x-code extensions.'}
    ], true);
  }

  ngOnInit() {
  }

}
