import { NgModule }             from '@angular/core';
import { BrowserModule }        from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }          from '@angular/forms';
import { HttpClientModule }     from '@angular/common/http';

import { AppRoutingModule }     from './app-routing.module';

import { AppComponent }         from './app.component';
import { HomeComponent }        from './home/home.component';
import { FooterComponent }      from './footer/footer.component';
import { HeaderComponent }      from './header/header.component';
import { FeaturesComponent }    from './features/features.component';
import { ContactComponent }     from './contact/contact.component';
import { DocsComponent } from './platform/docs/docs.component';
import { DesignComponent } from './platform/design/design.component';
import { PricingComponent } from './pricing/pricing.component';
import { CasesComponent } from './cases/cases.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TestingComponent } from './platform/tests/tests.component';
import { MocksComponent } from './platform/mocks/mocks.component';
import { AboutComponent } from './about/about.component';
import { StartComponent } from './start/start.component';
import { HttpService } from './services/http.service';
import { OurServicesComponent } from './our-services/our-services.component';
import { ErrorComponent } from './error/error.component';
import { InsightsComponent } from './platform/insights/insights.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FeaturesComponent,
    ContactComponent,
    DocsComponent,
    DesignComponent,
    TestingComponent,
    MocksComponent,
    InsightsComponent,
    PricingComponent,
    CasesComponent,
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    NotFoundComponent,
    StartComponent,
    ErrorComponent,
    OurServicesComponent
  ],
  providers: [ HttpService ],
  bootstrap: [ AppComponent ]
})
export class AppModule { 

}
