import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: [ './privacy.component.css' ]
})
export class PrivacyComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Privacy Policy - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'RestCase protects your privacy and will not pass your data to any 3rd party without your knowledge.'}
    ], true);
  }

  ngOnInit() {
  } 

}
