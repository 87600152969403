import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: [ './cases.component.css' ]
})
export class CasesComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("API design-first Use Cases - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'API design-first approach use cases, benefits and client stories.'}
    ], true);
  }

  ngOnInit() {
  }

}
