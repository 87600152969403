import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: [ './tests.component.css' ]
})
export class TestingComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("API Automatic Testing - Features - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'API coverage tests, including security tests, are generated automatically.'}
    ], true);
  }

  ngOnInit() {
  }

}
