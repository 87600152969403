import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { flatMap, catchError, map } from "rxjs/operators";

@Injectable()
export class HttpService {
  constructor(private _http: HttpClient) {
  }

  buildHeaders(additionalHeaders: Record<string, string>): HttpHeaders {
    let headers = new HttpHeaders(additionalHeaders);
    return headers;
  }

  private buildParams(params: Record<string, any>): HttpParams {
    let httpParams = new HttpParams({ fromObject: params });
    return httpParams;
  }

  get<T = any>(
    url: string,
    params: Record<string, any> = {},
    toggleLoader: boolean = false,
    additionalHeaders: Record<string, string> = {}
  ): Observable<HttpResponse<T>> {
    const headers: HttpHeaders = this.buildHeaders(additionalHeaders);

    const httpParams: HttpParams = this.buildParams(params);

    const options: {
      headers?: HttpHeaders;
      observe: "response";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    } = {
      headers: headers,
      params: httpParams,
      observe: "response",
      responseType: "json",
      withCredentials: true
    };
    // return this._http.get<T>(url, options);
    return this._http.get<T>(url, options);
  }

  post<T = any>(
    url: string,
    body: any,
    toggleLoader: boolean = false,
    params: Record<string, any> = {},
    additionalHeaders: Record<string, string> = {}
  ): Observable<HttpResponse<T>> {
    const headers: HttpHeaders = this.buildHeaders(additionalHeaders);

    const httpParams: HttpParams = this.buildParams(params);

    const options: {
      headers?: HttpHeaders;
      observe: "response";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    } = {
      headers: headers,
      params: httpParams,
      observe: "response",
      responseType: "json",
      withCredentials: true
    };
    // return this._http.get<T>(url, options);
    return this._http.post<T>(url, body, options);
  }
  patch<T = any>(
    url: string,
    body: any,
    toggleLoader: boolean = false,
    params: Record<string, any> = {},
    additionalHeaders: Record<string, string> = {}
  ): Observable<HttpResponse<T>> {
    const headers: HttpHeaders = this.buildHeaders(additionalHeaders);

    const httpParams: HttpParams = this.buildParams(params);

    const options: {
      headers?: HttpHeaders;
      observe: "response";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    } = {
      headers: headers,
      params: httpParams,
      observe: "response",
      responseType: "json",
      withCredentials: true
    };
    // return this._http.get<T>(url, options);
    return this._http.patch<T>(url, body, options);
  }
  delete<T = any>(
    url: string,
    params: Record<string, any> = {},
    toggleLoader: boolean = false,
    additionalHeaders: Record<string, string> = {}
  ): Observable<HttpResponse<T>> {
    const headers: HttpHeaders = this.buildHeaders(additionalHeaders);

    const httpParams: HttpParams = this.buildParams(params);

    const options: {
      headers?: HttpHeaders;
      observe: "response";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    } = {
      headers: headers,
      params: httpParams,
      observe: "response",
      responseType: "json",
      withCredentials: true
    };
    // return this._http.get<T>(url, options);
    return this._http.delete<T>(url, options);
  }
  put<T = any>(
    url: string,
    body: any,
    toggleLoader: boolean = false,
    params: Record<string, any> = {},
    additionalHeaders: Record<string, string> = {}
  ): Observable<HttpResponse<T>> {
    const headers: HttpHeaders = this.buildHeaders(additionalHeaders);

    const httpParams: HttpParams = this.buildParams(params);

    const options: {
      headers?: HttpHeaders;
      observe: "response";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType?: "json";
      withCredentials?: boolean;
    } = {
      headers: headers,
      params: httpParams,
      observe: "response",
      responseType: "json",
      withCredentials: true
    };
    // return this._http.get<T>(url, options);
    // return this.buildUrl(url, params).pipe(
      return this._http.put<T>(url, body, options);
  }

  // private buildUrl(
  //   url: string,
  //   params: Record<string, any> = {}
  // ): Observable<string> {
  //   let firstIteration = true,
  //     symbol = "?";
  //   for (let primary in params) {
  //     url += `${symbol}${primary}=${params[primary]}`;
  //     if (firstIteration) {
  //       symbol = "&";
  //       firstIteration = false;
  //     }
  //   }
  //   return of(url);
  // }
}
