import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: [ './pricing.component.css' ]
})
export class PricingComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Pricing Packages Information - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'RestCase platform pricing packages information.'}
    ], true);
  }

  ngOnInit() {
  }

}
