import { Component, OnInit } from '@angular/core';
import emailjs from 'emailjs-com';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  today: number = Date.now();
  fromEmail: string = '';
  showEmail: boolean = true;
  inProgress: boolean = false;

  constructor() {}

  ngOnInit() {
  }

  submit() {
    const service_id = "default_service";
    const template_id = "template_rc_demo";

    let template_params = {
      "from": this.fromEmail
    };

    let me = this;
    this.inProgress = true;

    emailjs.send(service_id, template_id, template_params)
      .then(function (response) {
        me.showEmail = false;
        me.inProgress = false;
      }, function (err) {
        me.showEmail = false;
        me.inProgress = false;
      });

  }

}
