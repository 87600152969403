import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: [ './terms.component.css' ]
})
export class TermsComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Terms and Conditions - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'RestCase terms and conditions for using the website and the platform.'}
    ], true);
  }

  ngOnInit() {
  } 

}
