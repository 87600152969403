import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: [ './about.component.css' ]
})
export class AboutComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
      this._titleService.setTitle("About - RestCase");
      this._metaService.addTags([
        {name: 'description', content: 'About RestCase, the company, the vision and the services explained.'}
      ], true);
  }

  ngOnInit() {
  }

}
