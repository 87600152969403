import { Component, OnInit } from '@angular/core';
import emailjs from 'emailjs-com';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: [ './contact.component.css' ]
})
export class ContactComponent implements OnInit {

  name: string = '';
  email: string = '';
  subject: string = '';
  message: string = '';
  
  notice: string = 'Please fill in all the fields.';
  showNotice: boolean = false;

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Contact Information - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'Contact information for RestCase, ways to communicate and contact.'}
    ], true);
  }

  ngOnInit() {
    emailjs.init("user_ZLqEszQ3orpwoLcz3gGh3");
  }

  submit() {
    let proceed = true;
    
    if (this.showNotice) {
      this.showNotice = false;
    }

    if ("" == this.name || "" == this.email || "" == this.subject || "" == this.message) {
      this.showNotice = true;
      proceed = false;
    }


    if (proceed) {
      const service_id = "default_service";
      const template_id = "template_restcase_front";

      let post_data = { 'name': this.name, 'email': this.email, 'from': this.email, 'subject': this.subject, 'message': this.message };
      let me = this;
      
      emailjs.send(service_id, template_id, post_data)
          .then(function(response) {
            me.notice = "Your message has been sent, Thank you!";
            me.showNotice = true;
            
            me.name = '';
            me.email = '';
            me.subject = '';
            me.message = '';

          }, function(err) {
              if (err.status == 601) {
                me.notice = "An error has occured in sending your contact email...";
                me.showNotice = true;
              } else if (err.status == 412) {
                me.notice = "Email address is not correct, please check...";
                me.showNotice = true;
              }
          });
  
    }

  }

}
