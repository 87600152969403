import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mocks',
  templateUrl: './mocks.component.html',
  styleUrls: [ './mocks.component.css' ]
})
export class MocksComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("API Smart Mocking - Features - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'API mocks that are generated as you work. Use the API virtualization services to start working without coding.'}
    ], true);
  }

  ngOnInit() {
  }

}
