import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.css' ]
})
export class ErrorComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Error Occcured - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'The page resulted in an error, sorry.'}
    ], true);
  }

  ngOnInit() {
  } 

}
