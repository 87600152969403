import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.css' ]
})
export class HomeComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
      this._titleService.setTitle("Design-first API Development, Supercharged! - RestCase");
      this._metaService.addTags([
        {name: 'description', content: 'RestCase supercharges the API development process resulting in improved team collaboration, reduced development time, better testing and increased overall productivity.'},
      ], true);
  }

  ngOnInit() {
  }

}
