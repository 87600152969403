import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {

  constructor(private _titleService: Title, private _metaService: Meta) { 
    this._titleService.setTitle("Our Services - RestCase");
    this._metaService.addTags([
      {name: 'description', content: 'API consulting services, API security consulting and penetration testing.'}
    ], true);
  }

  ngOnInit() {
  }

}
